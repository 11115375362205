import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Pagination`}</em>{` is used for splitting up content or data into several pages, with a control for navigating to the next or previous page.`}</p>
    <p>{`Generally, pagination is used if there are more than 25 items displayed in one view. The default number displayed will vary depending on the context.`}</p>
    <h2 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h2>
    <h4 {...{
      "id": "identify-the-current-page"
    }}>{`Identify the current page`}</h4>
    <p>{`Clearly identify which page the user is on by displaying the current page number. By providing context into how many pages there are in total (eg. 1 of 4 pages), you can help provide clarity around the data displayed.`}</p>
    <h4 {...{
      "id": "provide-various-options-for-navigating"
    }}>{`Provide various options for navigating`}</h4>
    <p><em parentName="p">{`Previous`}</em>{` and `}<em parentName="p">{`next`}</em>{` chevrons or links are the most useful way for the user to move forward or backward through pages of data. Provide an `}<a parentName="p" {...{
        "href": "/components/select"
      }}>{`inline select`}</a>{` in which users can choose the page they wish to navigate to.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.18478260869565%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of pagination controls on data table",
        "title": "Example of pagination controls on data table",
        "src": "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png",
        "srcSet": ["/static/facb1d9bfd75f442947813c02c285fef/d6747/pagination-usage-1.png 288w", "/static/facb1d9bfd75f442947813c02c285fef/09548/pagination-usage-1.png 576w", "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png 1152w", "/static/facb1d9bfd75f442947813c02c285fef/c3e47/pagination-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.18478260869565%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of pagination controls on data table",
        "title": "Example of pagination controls on data table",
        "src": "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png",
        "srcSet": ["/static/facb1d9bfd75f442947813c02c285fef/d6747/pagination-usage-1.png 288w", "/static/facb1d9bfd75f442947813c02c285fef/09548/pagination-usage-1.png 576w", "/static/facb1d9bfd75f442947813c02c285fef/fb070/pagination-usage-1.png 1152w", "/static/facb1d9bfd75f442947813c02c285fef/c3e47/pagination-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "items-per-page"
    }}>{`Items per page`}</h4>
    <p>{`Use an inline select within the pagination bar so the user can change the amount of data displayed per page.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.18478260869565%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of items per page on data table",
        "title": "Example of items per page on data table",
        "src": "/static/993b7c3ec152cc0581ca7b1ba2465f8a/fb070/pagination-usage-2.png",
        "srcSet": ["/static/993b7c3ec152cc0581ca7b1ba2465f8a/d6747/pagination-usage-2.png 288w", "/static/993b7c3ec152cc0581ca7b1ba2465f8a/09548/pagination-usage-2.png 576w", "/static/993b7c3ec152cc0581ca7b1ba2465f8a/fb070/pagination-usage-2.png 1152w", "/static/993b7c3ec152cc0581ca7b1ba2465f8a/c3e47/pagination-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      